import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
import "./Catalog.css";
function EditDepartment(props) {


    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [department, setDepartment] = useState({});
    const [departmentID, setDepartmentID] = useState('');
    useEffect(() => {
        getDepartmentDetails();
    }, [props.department_id]);

    const getDepartmentDetails = () => {
        axios(`Xtras/department_details?department_id=${props.department_id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setDepartment(response.data.department);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", department.name);
        formdata.append("logo", department.logo);
        formdata.append("cc_email", department.cc_email);
        formdata.append("id", props.department_id);
        axios("Xtras/update_departments", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    alert.success(response.data.success);
                    props.getDepartments();
                    props.setIsOpen(false);
                } else {
                    setLoading(false);
                    alert.error(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.error(err.response.data.error);
            });
    };

    return (
        <div class="container">
            <form method="POST">
                <div class="form-group">
                    <label for="name">Name:</label>
                    <input type="text" id="name" onChange={(e) => setDepartment({
                        ...department,
                        name: e.target.value,
                    })} value={department.name} name="name" className="form-control" />
                </div>
                <div class="form-group edit-dep-logo">
                    <label for="email">Logo URL:</label>
                    <hr></hr>
                    {department.logo &&
                    (<img src={department.logo} className="dep-modal-logo"></img>)}
                    <input type="email" id="email" onChange={(e) => setDepartment({
                        ...department,
                        logo: e.target.value,
                    })} value={department.logo} name="email" className="form-control" />
                </div>
                
                <div class="form-group mt-2">
                    <label for="email">Email CC:</label>
                    <input type="email" id="email" onChange={(e) => setDepartment({
                        ...department,
                        cc_email: e.target.value,
                    })} value={department.cc_email} name="email" className="form-control" />
                </div>
                <div class="form-group">
                    <button type="button" onClick={handleSubmit} className="btn btn-danger btn-sm "  >UPDATE</button>
                </div>
            </form>
        </div>
    );
}

export default EditDepartment;