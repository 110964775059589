import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import EditRole from "./EditRole";
import AddRole from "./AddRole";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import "./Users.css";

import IsAllowed from "../../Utils/IsAllowed";
function Roles() {
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        getRoles();
    }, []);

    const getRoles = () => {
        axios("Users/roles", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setRoles(response.data.roles);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    return (
        <div>
            <div class="container-fluid">
                <h1 class="h3 mb-2 text-gray-800">Roles</h1>

                <div class="card shadow mb-4">
                    <div class="card-body">
                        <div class="container-fluid">
                            <div style={{ marginTop: 50 }}>
                                <div className="table-responsive">
                                    <LoadingOverlay
                                        active={loading}
                                        styles={{
                                            overlay: (base) => ({
                                                ...base,
                                                background: "rgba(255, 255, 255, 0.9)",
                                            }),
                                            spinner: (base) => ({
                                                ...base,
                                                width: "75px",
                                                "& svg circle": {
                                                    stroke: "rgba(0, 0, 0, 0.8)",
                                                },
                                            }),
                                        }}
                                        spinner={<RingLoader loading={loading} color="#36d7b7" />}
                                        text="..."
                                    >
                                        <table className="table table-bordered  ">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Name</th>
                                                    <th>Created Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {roles.length > 0 ? roles.map((value, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{value.role_name}</td>
                                                            <td>{value.created_at}</td>
                                                            <td className="space-evenly">
                                                                {IsAllowed(["2"]) && (
                                                                    <button className="btn btn-sm btn-primary" title="Edit" ><i className="fa fa-pen"></i></button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                }) : (<td colSpan={4} className="text-center"> No Roles</td>)}
                                            </tbody>
                                        </table>
                                    </LoadingOverlay>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Roles;
