import React, { useEffect, useState, useRef } from "react";
//import LoadingOverlay from "react-loading-overlay";
//import RingLoader from "react-spinners/RingLoader";
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import $ from "jquery";
import IsAllowed from "../../Utils/IsAllowed";

//import IsAllowed from "../Utils/IsAllowed";

function SideBar() {
  const [user, setUser] = useState({});
  //const [loading, setLoading] = useState(false);
  const alert = useAlert();
  useEffect(() => {
    getUserData();
  }, []);
  const getUserData = () => {
    //setLoading(true);
    axios(`Login/department_info`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    }).then((res) => {
      if (res.status === 200) {
        //setLoading(false);
        setUser(res.data.department);
      } else {
        //setLoading(false);
        alert.show(res.error);
      }
    });
  };
  function toggle() {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
    if ($(".sidebar").hasClass("toggled")) {
      $(".sidebar .collapse").collapse("hide");
    }
  }

  return (
    <ul
      class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion "
      id="accordionSidebar"
    >
      <Link
        class="sidebar-brand d-flex align-items-center justify-content-center"
        to="/"
      >
        <div class="sidebar-brand-icon ">
          {user && user.logo ? (
            <img src={user.logo} height="50px" className="p-2"></img>
          ) : (
            <img src="/no-image-icon.png" height="50px" className="p-2"></img>
          )}
        </div>
      </Link>

      <hr class="sidebar-divider my-0" />

      <li class="nav-item active">
        <Link class="nav-link" to="/">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span>Dashboard</span>
        </Link>
      </li>

      <hr class="sidebar-divider" />
      {IsAllowed(["1"]) && (
        <li class="nav-item">
          <a
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <i class="fa fa-bullhorn"></i>
            <span>Leads</span>
          </a>
          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              {IsAllowed(["10101"]) && (
                <Link class="collapse-item" to="/leads">
                  Leads
                </Link>
              )}
              {IsAllowed(["10102"]) && (
                <Link class="collapse-item" to="/add-leads">
                  Add Lead
                </Link>
              )}
              {IsAllowed(["10107"]) && (
                <Link class="collapse-item" to="/add-bulk-leads">
                  Add Bulk Lead
                </Link>
              )}
            </div>
          </div>
        </li>
      )}
      {IsAllowed(["2"]) && (
        <li class="nav-item">
          <a
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i class="fa fa-sitemap"></i>
            <span>Catalogs</span>
          </a>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              {IsAllowed(["20201"]) && (
                <Link class="collapse-item" to="/departments">
                  Departments
                </Link>
              )}
              {IsAllowed(["20101"]) && (
                <Link class="collapse-item" to="/sources">
                  Sources
                </Link>
              )}
            </div>
          </div>
        </li>
      )}

      {IsAllowed(["3"]) && (
        <li class="nav-item">
          <a
            class="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseThree"
            aria-expanded="true"
            aria-controls="collapseThree"
          >
            <i class="fa fa-users"></i>
            <span>User Management</span>
          </a>
          <div
            id="collapseThree"
            class="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordionSidebar"
          >
            <div class=" py-2 collapse-inner rounded">
              {IsAllowed(["30101"]) && (
                <Link class="collapse-item" to="/users">
                  Users
                </Link>
              )}
              {IsAllowed(["30201"]) && (
                <Link class="collapse-item" to="/roles">
                  Roles
                </Link>
              )}
            </div>
          </div>
        </li>
      )}

      <div class="text-center d-none d-md-inline">
        <button
          class="rounded-circle border-0"
          onClick={toggle}
          id="sidebarToggle"
        ></button>
      </div>
    </ul>
  );
}

export default SideBar;
