import React, { useState, useEffect } from "react";
import SelectSearch from 'react-select-search';
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import "./Leads.css"

function AddLeads() {
    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [sources, setSources] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [departmentUsers, setDepartmentUsers] = useState([]);
    const [country, setCountry] = useState(228);
    const [source, setSource] = useState('');
    const [assignedTo, setAssignedTo] = useState(0);
    const [query, setQuery] = useState('');
    const [comment, setComment] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [department, setDepartment] = useState('');
    const [isdCode, setIsdCode] = useState('+971');


    const getCountries = () => {
        axios("Xtras/countries", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setCountries(response.data.countries);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const getCountryByCode = () => {
        axios(`Xtras/country_by_code?code=${isdCode.replace('+', '')}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setCountry(response.data.country)
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    }

    const getDepartments = () => {
        axios("Xtras/departments", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setDepartments(response.data.departments);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const getAssignee = (id) => {
        axios(`Leads/department_users?department=${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setDepartmentUsers(response.data.department_users);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const getSources = () => {
        axios("Xtras/sources", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setSources(response.data.sources);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const selectDepartment = (id) => {
        setDepartment(id);
        setAssignedTo(0);
        getAssignee(id);
    };

    const selectCountry = (id) => {
        setCountry(id);
        countries.some(item => {
            if (id === item.value) {
                setIsdCode('+' + item.isd_code);
            }
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", name);
        formdata.append("mobile", mobile);
        formdata.append("source", source);
        formdata.append("department", department);
        formdata.append("country", country);
        formdata.append("assigned_to", assignedTo);
        formdata.append("query", query);
        formdata.append("comment", comment);
        formdata.append("email", email);
        axios("Leads/add_leads", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    alert.success(response.data.success);
                    history.push({
                        pathname: "/leads",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    alert.error(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.error(err.response.data.error);
            });
    };

    useEffect(() => {
        getCountries();
        getDepartments();
        getSources();
    }, []);
    return (
        <div class="container">
            <LoadingOverlay
                active={loading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: "75px",
                        "& svg circle": {
                            stroke: "rgba(0, 0, 0, 0.8)",
                        },
                    }),
                }}
                spinner={<RingLoader loading={loading} color="#36d7b7" />}
                text="..."
            >
                <form method="POST">
                    <div class="form-group">
                        <label for="name">Name:</label>
                        <input type="text" id="name" onChange={(e) => setName(e.target.value)} value={name} name="name" className="form-control" />
                    </div>
                    <div class="form-group">
                        <label for="email">Email:</label>
                        <input type="email" id="email" onChange={(e) => setEmail(e.target.value)} value={email} name="email" className="form-control" />
                    </div>
                    <div class="form-group">
                        <label for="country">Country:</label>
                        <SelectSearch options={countries} onChange={selectCountry} value={country} id="country" name="country" search placeholder="Choose your Country" />
                    </div>
                    <div class="form-group">
                        <label for="phone">Phone:</label>
                        <input type="tel" id="phone" name="phone_country_code" onChange={(e) => setIsdCode(e.target.value)} onBlur={(e) => getCountryByCode(e.target.value)} className="country-code" value={isdCode} placeholder={isdCode} required />
                        <input type="tel" id="phone" name="phone_number" onChange={(e) => setMobile(e.target.value)} value={mobile} className="phone-number" required />
                    </div>
                    <div class="form-group">
                        <label for="source">Source:</label>
                        <SelectSearch options={sources} onChange={setSource} value={source} id="source" name="source" search placeholder="Choose your source" />
                    </div>
                    <div class="form-group">
                        <label for="department">Department:</label>
                        <SelectSearch options={departments} onChange={selectDepartment} value={department} id="department" name="department" search placeholder="Choose your department" />
                    </div>
                    <div class="form-group">
                        <label for="assigned_to">Assigned To:</label>
                        <SelectSearch options={departmentUsers} onChange={setAssignedTo} value={assignedTo} id="assigned_to" name="assigned_to" search placeholder="Choose your Assignee" />
                    </div>
                    <div class="form-group">
                        <label for="query">Query:</label>
                        <textarea id="query" name="query" onChange={(e) => setQuery(e.target.value)} className="form-control" required>{query}</textarea>
                    </div>
                    <div class="form-group">
                        <label for="comment">Comment:</label>
                        <textarea id="comment" name="comment" onChange={(e) => setComment(e.target.value)} className="form-control" >{comment}</textarea>
                    </div>
                    <div class="form-group">
                        <button type="button" onClick={handleSubmit} className="btn btn-danger btn-sm "  >Submit</button>
                    </div>
                </form>
            </LoadingOverlay>
        </div>
    )
}

export default AddLeads