import React, { useState, useEffect } from "react";
import SelectSearch from 'react-select-search';
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import "./Users.css"



function AddRole() {
  const alert = useAlert();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [name, setName] = useState('');

  const data = [{
    label: 'Leads',
    value: '1',
    children: [
      {
        label: 'Leads List',
        value: '101',
        children: [
          {
            label: 'Leads List View',
            value: '10101',
          },
          {
            label: 'Leads Add',
            value: '10102',
          },
          {
            label: 'Lead Detail View',
            value: '10103',
          },
          {
            label: 'Lead Upadate',
            value: '10104',
          },
          {
            label: 'Lead Status Change',
            value: '10105',
          },
          {
            label: 'Lead Change Assignee',
            value: '10106',
          },
          {
            label: 'Bulk Lead Add',
            value: '10107',
          },
        ],
      },
    ],
  }, {
    label: 'Catalogs',
    value: '2',
    children: [
      {
        label: 'Source List',
        value: '201',
        children: [
          {
            label: 'Source List View',
            value: '20101',
            checked: "true",
          },
          {
            label: 'Source Add',
            value: '20102',
            checked: "true",
          },
          {
            label: 'Source Edit',
            value: '20103',
          },
        ],
      },
      {
        label: 'Department List',
        value: '202',
        children: [
          {
            label: 'Department List View',
            value: '20201',
          },
          {
            label: 'Department Add',
            value: '20202',
          },
          {
            label: 'Department Edit',
            value: '20203',
          },
        ],
      },
    ],
  },
  {
    label: 'User Management',
    value: '3',
    children: [
      {
        label: 'User List',
        value: '301',
        children: [
          {
            label: 'User List View',
            value: '30101',
            checked: "true",
          },
          {
            label: 'User Add',
            value: '30102',
            checked: "true",
          },
          {
            label: 'User Detail View',
            value: '30103',
            checked: "true",
          },
          {
            label: 'User Edit',
            value: '30104',
          },
        ],
      },
      {
        label: 'Roles List',
        value: '302',
        children: [
          {
            label: 'Role List View',
            value: '30201',
          },
          {
            label: 'Role Add',
            value: '30202',
          },
          {
            label: 'Role Detail View',
            value: '30203',
            checked: "true",
          },
          {
            label: 'Role Edit',
            value: '30204',
          },
        ],
      },
    ],
  }]




  const getResources = () => {

  };

  const onChange = (currentNode, selectedNodes) => {
    console.log('onChange::', currentNode, selectedNodes)
  }
  const onAction = (node, action) => {
    console.log('onAction::', action, node)
  }
  const onNodeToggle = currentNode => {
    console.log('onNodeToggle::', currentNode)
  }



  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let formdata = new FormData();
    formdata.append("role_name", name);
    formdata.append("role_resources", resources);
    axios("Users/add_role", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          history.push({
            pathname: "/roles",
            state: { data: true },
          });
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.error(err.response.data.error);
      });
  };

  useEffect(() => {
    getResources();
  }, []);
  return (
    <div class="container">
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner={<RingLoader loading={loading} color="#36d7b7" />}
        text="..."
      >
        <form method="POST">
          <div class="form-group">
            <label for="name">Name:</label>
            <input type="text" id="name" onChange={(e) => setName(e.target.value)} value={name} name="name" className="form-control" />
          </div>
          <DropdownTreeSelect data={data} onChange={onChange} onAction={onAction} onNodeToggle={onNodeToggle} />
          <div class="form-group">
            <button type="button" onClick={handleSubmit} className="btn btn-danger btn-sm "  >Submit</button>
          </div>
        </form>
      </LoadingOverlay>
    </div>
  )
}

export default AddRole