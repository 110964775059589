import React, { useEffect, useState } from "react";
import SelectSearch from 'react-select-search';
import { useHistory } from "react-router-dom";
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import "./Leads.css"
import IsAllowed from "../../Utils/IsAllowed";


function EditLeads(props) {
  const alert = useAlert();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [sources, setSources] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [statusHistory, setStatusHistory] = useState([]);
  const [departmentUsers, setDepartmentUsers] = useState([]);
  const [comment, setComment] = useState('');
  const [lead, setLead] = useState({});
  const [leadId, setLeadId] = useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );
  useEffect(() => {
    getCountries();
    getDepartments();
    getSources();
    getStatuses();
  }, []);

  useEffect(() => {
    getLeadDetails();
  }, [leadId]);
  const getLeadDetails = () => {
    setLoading(true);
    axios("Leads/lead_detail?lead_id=" + leadId, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLead(response.data.lead);
          setStatusHistory(response.data.status_history);
          setDepartmentUsers(response.data.department_users);
          setLoading(false);
        } else {
          //setLoading(false);
          alert.show(response.data.error);
          history.push({
            pathname: "/leads",
            state: { data: true },
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
        history.push({
          pathname: "/leads",
          state: { data: true },
        });
      });
  };
  const getCountries = () => {
    axios("Xtras/countries", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setCountries(response.data.countries);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  const getDepartments = () => {
    axios("Xtras/departments", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setDepartments(response.data.departments);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  const getSources = () => {
    axios("Xtras/sources", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setSources(response.data.sources);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  const getStatuses = () => {
    axios("Xtras/statuses", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setStatuses(response.data.statuses);
        } else {
          setLoading(false);
          alert.show(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  const updateLead = () => {

    setLoading(true);
    let formdata = new FormData();
    formdata.append("name", lead.customer_name);
    formdata.append("id", leadId);
    formdata.append("email", lead.customer_email);
    formdata.append("mobile", lead.customer_mobile);
    formdata.append("country", lead.country);
    formdata.append("source", lead.source);
    formdata.append("department", lead.department);
    formdata.append("query", lead.query);

    axios(`Leads/update_lead`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          getLeadDetails();
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };

  const selectCountry = (id) => {
    setLead({
      ...lead,
      country: id,
    })
    countries.some(item => {
      if (id === item.value) {
        setLead({
          ...lead,
          isd_code: '+' + item.isd_code,
        })
      }
    });
  };

  const updateStatus = () => {

    setLoading(true);
    let formdata = new FormData();
    formdata.append("id", leadId);
    formdata.append("comment", comment);
    formdata.append("status", lead.status);

    axios(`Leads/update_status`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          getLeadDetails();
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };


  const updateAssignee = () => {

    setLoading(true);
    let formdata = new FormData();
    formdata.append("id", leadId);
    formdata.append("assigned_to", lead.assigned_to);

    axios(`Leads/update_assignee`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          alert.success(response.data.success);
          getLeadDetails();
        } else {
          setLoading(false);
          alert.error(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert.show(err.response.data.error);
      });
  };


  return (
    <div class="container">
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "rgba(0, 0, 0, 0.8)",
            },
          }),
        }}
        spinner={<RingLoader loading={loading} color="#36d7b7" />}
        text="..."
      >
        <div class="row">
          <div class="lead-name mt-3">Lead Id : {lead.lead_no}</div>
          <div class="col-md-8 pl-4 pr-5">
            <form method="POST">
              <div class="form-group">
                <label for="name">Name:</label>
                <input type="text" id="name" name="name" onChange={(e) =>
                  setLead({
                    ...lead,
                    customer_name: e.target.value,
                  })
                } value={lead.customer_name} class="form-control" />
              </div>
              <div class="form-group">
                <label for="email">Email:</label>
                <input type="email" id="email" name="email" onChange={(e) =>
                  setLead({
                    ...lead,
                    customer_email: e.target.value,
                  })
                } value={lead.customer_email} class="form-control" />
              </div>
              <div class="form-group">
                <label for="country">Country:</label>
                <SelectSearch options={countries} id="country" value={lead.country} disabled onChange={(e) => selectCountry(e)}
                  name="country" search placeholder="Choose your Country" />
              </div>
              <div class="form-group">
                <label for="phone">Phone:</label>
                <input type="tel" id="phone" name="phone_country_code" class="country-code" value={lead.isd_code} placeholder={lead.isd_code} required />
                <input type="tel" id="phone" name="phone_number" class="phone-number" onChange={(e) =>
                  setLead({
                    ...lead,
                    customer_mobile: e.target.value,
                  })
                } value={lead.customer_mobile} required />
              </div>
              <div class="form-group">
                <label for="source">Source:</label>
                <SelectSearch options={sources} id="source" name="source" onChange={(e) =>
                  setLead({
                    ...lead,
                    source: e,
                  })
                } value={lead.source} search placeholder="Choose your source" />
              </div>
              <div class="form-group">
                <label for="department">Department:</label>
                <SelectSearch options={departments} id="department" name="department" onChange={(e) =>
                  setLead({
                    ...lead,
                    department: e,
                  })
                } value={lead.department}  search placeholder="Choose your department" />
              </div>
              <div class="form-group">
                <label for="query">Query:</label>
                <textarea id="query" name="query" class="form-control" onChange={(e) =>
                  setLead({
                    ...lead,
                    query: e.target.value,
                  })
                } value={lead.query} >{lead.query}</textarea>
              </div>

              <div class="form-group">
                {IsAllowed(["10104"]) && (
                  <button type="button" class="btn btn-success btn-sm " onClick={updateLead}  >UPDATE</button>
                )}
              </div>
            </form>
          </div>
          <div class="col-md-4">
            <div class="col-md-12 status-box">
              <form method="POST">
                <div class="form-group">
                  <label for="statuses">Status</label>
                  <SelectSearch options={statuses} id="statuses" name="statuses" onChange={(e) =>
                    setLead({
                      ...lead,
                      status: e,
                    })
                  } value={lead.status} search placeholder="Choose your Status" />
                </div>
                <div class="form-group">
                  <label for="comment">Comment:</label>
                  <textarea id="comment" name="comment" class="form-control" onChange={(e) => setComment(e.target.value)}  >{comment}</textarea>
                </div>
                <div class="form-group text-center mb-0">
                  {IsAllowed(["10105"]) && (
                    <button type="button" class="btn btn-info btn-sm " onClick={updateStatus}  >Change</button>
                  )}
                </div>
              </form>
            </div>
            <div class="col-md-12 status-box">
              <form method="POST">
                <div class="form-group">
                  <label for="statuses">Assigned To</label>
                  <SelectSearch options={departmentUsers} id="assigned_to" name="assigned_to" onChange={(e) =>
                    setLead({
                      ...lead,
                      assigned_to: e,
                    })
                  } value={lead.assigned_to} search placeholder="Choose your User" />
                </div>
                <div class="form-group text-center mb-0">
                  {IsAllowed(["10106"]) && (
                    <button type="button" class="btn btn-info btn-sm " onClick={updateAssignee}  >Change</button>
                  )}
                </div>
              </form>
            </div>
            <h6>Status history</h6>
            <ul class="timeline">
              {statusHistory && statusHistory.map((value, index) => {
                return (
                  <>
                    <li className={"alert alert-" + value.style}>
                      <h5>{value.name}</h5>
                      <div class="mb-0 d-flex justify-content-between"><span><i className="fa fa-calendar"></i> {value.created_date}      </span><span> <i className="fa fa-clock"></i> {value.created_time}</span></div>
                      <p class="w-100 timeline-text">{value.comment}</p>
                      <p><i className="fa fa-user"></i> {value.updated_by}</p>
                    </li>
                  </>
                )
              })}

            </ul>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  )
}

export default EditLeads