import React, { useState, useEffect } from "react";
import SelectSearch from 'react-select-search';
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import "./Users.css"

function AddUser() {
    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [userRoleId, setUserRoleId] = useState('');
    const [userType, setUserType] = useState('');
    const [department, setDepartment] = useState('');
    const [roles, setRoles] = useState([]);
    const [countries, setCountries] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [country, setCountry] = useState(228);
    const [isdCode, setIsdCode] = useState('+971');
    const [userTypes, setUserTypes] = useState([]);
    const getRoles = () => {
        axios("Users/all_roles", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setRoles(response.data.roles);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };


    const getCountries = () => {
        axios("Xtras/countries", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setCountries(response.data.countries);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const getDepartments = () => {
        axios("Xtras/departments", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setDepartments(response.data.departments);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const getUserTypes = () => {
        axios("Users/all_user_types", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setUserTypes(response.data.user_types);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const selectCountry = (id) => {
        setCountry(id);
        countries.some(item => {
            if (id === item.value) {
                setIsdCode('+' + item.isd_code);
            }
        });
    };




    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("firstname", firstName);
        formdata.append("lastname", lastName);
        formdata.append("email", email);
        formdata.append("mobile", mobile);
        formdata.append("country", country);
        formdata.append("user_role_id", userRoleId);
        formdata.append("user_type", userType);
        formdata.append("department", department);
        axios("Users/add_user", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    alert.success(response.data.success);
                    history.push({
                        pathname: "/users",
                        state: { data: true },
                    });
                } else {
                    setLoading(false);
                    alert.error(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.error(err.response.data.error);
            });
    };

    useEffect(() => {
        getRoles();
        getCountries();
        getDepartments();
        getUserTypes();
    }, []);
    return (
        <div class="container">
            <LoadingOverlay
                active={loading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: "75px",
                        "& svg circle": {
                            stroke: "rgba(0, 0, 0, 0.8)",
                        },
                    }),
                }}
                spinner={<RingLoader loading={loading} color="#36d7b7" />}
                text="..."
            >
                <form method="POST">
                    <div class="form-group">
                        <label for="name">First Name:</label>
                        <input type="text" id="firstName" onChange={(e) => setFirstName(e.target.value)} value={firstName} name="firstName" className="form-control" />
                    </div>
                    <div class="form-group">
                        <label for="name">Last Name:</label>
                        <input type="text" id="lastName" onChange={(e) => setLastName(e.target.value)} value={lastName} name="lastName" className="form-control" />
                    </div>
                    <div class="form-group">
                        <label for="name">Email:</label>
                        <input type="text" id="email" onChange={(e) => setEmail(e.target.value)} value={email} name="email" className="form-control" />
                    </div>
                    <div class="form-group">
                        <label for="country">Country:</label>
                        <SelectSearch options={countries} onChange={selectCountry} value={country} id="country" name="country" search placeholder="Choose your Country" />
                    </div>
                    <div class="form-group">
                        <label for="phone">Phone:</label>
                        <input type="tel" id="phone" name="phone_country_code" className="country-code" value={isdCode} placeholder={isdCode} required />
                        <input type="tel" id="phone" name="phone_number" onChange={(e) => setMobile(e.target.value)} value={mobile} className="phone-number" required />
                    </div>

                    <div class="form-group">
                        <label for="department">Department:</label>
                        <SelectSearch options={departments} onChange={setDepartment} value={department} id="department" name="department" search placeholder="Choose your department" />
                    </div>
                    <div class="form-group">
                        <label for="country">User Type:</label>
                        <SelectSearch options={userTypes} onChange={setUserType} value={userType} id="user_type" name="user_type" search placeholder="Choose your User Type" />
                    </div>
                    <div class="form-group">
                        <label for="country">User Role:</label>
                        <SelectSearch options={roles} onChange={setUserRoleId} value={userRoleId} id="user_role_id" name="user_role_id" search placeholder="Choose your User Role Id" />
                    </div>

                    <div class="form-group">
                        <button type="button" onClick={handleSubmit} className="btn btn-danger btn-sm "  >Submit</button>
                    </div>
                </form>
            </LoadingOverlay>
        </div>
    )
}

export default AddUser