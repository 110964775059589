import React, { useEffect, useState } from "react";
import SelectSearch from 'react-select-search';
import { useHistory } from "react-router-dom";
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import IsAllowed from "../../Utils/IsAllowed";
import "./Users.css"


function EditUser(props) {
    const alert = useAlert();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [sources, setSources] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [userTypes, setUserTypes] = useState([]);
    const [password, setPassword] = useState('');
    const [roles, setRoles] = useState([]);

    const [user, setUser] = useState({});
    const [userId, setUserId] = useState(
        props.match && props.match.params && props.match.params.id
            ? props.match.params.id
            : ""
    );
    useEffect(() => {
        getCountries();
        getDepartments();
        getRoles();
        getUserTypes();

    }, []);

    useEffect(() => {
        getUserDetails();
    }, [userId]);
    const getUserDetails = () => {
        setLoading(true);
        axios("Users/user_detail?user_id=" + userId, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setUser(response.data.user);
                    setLoading(false);
                } else {
                    //setLoading(false);
                    alert.show(response.data.error);
                    history.push({
                        pathname: "/users",
                        state: { data: true },
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
                history.push({
                    pathname: "/users",
                    state: { data: true },
                });
            });
    };
    const getCountries = () => {
        axios("Xtras/countries", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setCountries(response.data.countries);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const getDepartments = () => {
        axios("Xtras/departments", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setDepartments(response.data.departments);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const getUserTypes = () => {
        axios("Users/all_user_types", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setUserTypes(response.data.user_types);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const randomString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        setPassword(result);
    }

    const getRoles = () => {
        axios("Users/all_roles", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setRoles(response.data.roles);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };


    const updateUser = () => {
        setLoading(true);
        let formdata = new FormData();
        formdata.append("firstname", user.firstname);
        formdata.append("lastname", user.lastname);
        formdata.append("password", password);
        formdata.append("id", userId);
        formdata.append("email", user.email);
        formdata.append("mobile", user.mobile);
        formdata.append("country", user.country);
        formdata.append("user_type", user.user_type);
        formdata.append("department_id", user.department_id);
        formdata.append("user_role_id", user.user_role_id);
        axios(`Users/update_user`, {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    alert.success(response.data.success);
                    getUserDetails();
                } else {
                    setLoading(false);
                    alert.error(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const selectCountry = (id) => {
        setUser({
            ...user,
            country: id,
        })
        countries.some(item => {
            if (id === item.value) {
                setUser({
                    ...user,
                    isd_code: '+' + item.isd_code,
                })
            }
        });
    };

    return (
        <div class="container">
            <LoadingOverlay
                active={loading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(255, 255, 255, 0.9)",
                    }),
                    spinner: (base) => ({
                        ...base,
                        width: "75px",
                        "& svg circle": {
                            stroke: "rgba(0, 0, 0, 0.8)",
                        },
                    }),
                }}
                spinner={<RingLoader loading={loading} color="#36d7b7" />}
                text="..."
            >
                <div class="row">
                    <div class="col-md-12 pl-4 pr-5">
                        <form method="POST">
                            <div class="form-group">
                                <label for="name">First Name:</label>
                                <input type="text" id="firstname" name="firstname" onChange={(e) =>
                                    setUser({
                                        ...user,
                                        firstname: e.target.value,
                                    })
                                } value={user.firstname} class="form-control" />
                            </div>
                            <div class="form-group">
                                <label for="name">Last Name:</label>
                                <input type="text" id="lastname" name="lastname" onChange={(e) =>
                                    setUser({
                                        ...user,
                                        lastname: e.target.value,
                                    })
                                } value={user.lastname} class="form-control" />
                            </div>
                            <div class="form-group">
                                <label for="email">Email:</label>
                                <input type="email" id="email" name="email" onChange={(e) =>
                                    setUser({
                                        ...user,
                                        email: e.target.value,
                                    })
                                } value={user.email} class="form-control" />
                            </div>
                            <div class="form-group">
                                <label for="country">Country:</label>
                                <SelectSearch options={countries} id="country" value={user.country} disabled onChange={(e) => selectCountry(e)}
                                    name="country" search placeholder="Choose your Country" />
                            </div>
                            <div class="form-group">
                                <label for="phone">Phone:</label>
                                <input type="tel" id="phone" name="phone_country_code" class="country-code" value={user.isd_code} placeholder={user.isd_code} required />
                                <input type="tel" id="phone" name="phone_number" class="phone-number" onChange={(e) =>
                                    setUser({
                                        ...user,
                                        mobile: e.target.value,
                                    })
                                } value={user.mobile} required />
                            </div>
                            <div class="form-group">
                                <label for="user_type">User Type:</label>
                                <SelectSearch options={userTypes} id="user_type" name="user_type" onChange={(e) =>
                                    setUser({
                                        ...user,
                                        user_type: e,
                                    })
                                } value={user.user_type} search placeholder="Choose your source" />
                            </div>
                            <div class="form-group">
                                <label for="roles">User Role:</label>
                                <SelectSearch options={roles} id="roles" name="roles" onChange={(e) =>
                                    setUser({
                                        ...user,
                                        user_role_id: e,
                                    })
                                } value={user.user_role_id} search placeholder="Choose your department" />
                            </div>
                            <div class="form-group">
                                <label for="department">Department:</label>
                                <SelectSearch options={departments} id="department" name="department" onChange={(e) =>
                                    setUser({
                                        ...user,
                                        department_id: e,
                                    })
                                } value={user.department_id} disabled search placeholder="Choose your department" />
                            </div>
                            <div class="form-group">
                                <label for="password">Password:</label>
                                <div class="row">
                                    <div class="col-md-11">
                                        <input type="text" id="password" name="password" onChange={(e) => setPassword(e.target.value)} value={password} class="form-control" />
                                    </div>
                                    <div class="col-md-1">
                                        <button type="button" class="btn btn-success btn-sm" onClick={() => randomString(16)}><i className="fa fa-sm fa-sync"></i></button>
                                    </div>
                                </div>

                            </div>

                            <div class="form-group">
                                {IsAllowed(["30104"]) && (
                                    <button type="button" class="btn btn-success btn-sm " onClick={updateUser}  >UPDATE</button>
                                )}
                            </div>
                        </form>
                    </div>

                </div>
            </LoadingOverlay>
        </div>
    )
}

export default EditUser