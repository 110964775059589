import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from "react-router-dom";
import AllLeads from './LeadsTabs/AllLeads'
import PendingLeads from './LeadsTabs/PendingLeads'
import CompletedLeads from './LeadsTabs/CompletedLeads'
import ProcessedLeads from "./LeadsTabs/ProcessedLeads";
import CancelledLeads from "./LeadsTabs/CancelledLeads";
import RFQSharedLeads from "./LeadsTabs/RFQSharedLeads";
import RejectedLeads from "./LeadsTabs/RjectedLeads";
import IrrelevantLeads from "./LeadsTabs/IrrelevantLeads";
import ReminderLeads from "./LeadsTabs/ReminderLeads";
import IsAllowed from "../../Utils/IsAllowed";


import './Leads.css';
function Leads(props) {
  const [allcount, setAllCount] = useState(0);
  const [pendingcount, setPendingCount] = useState(0);
  const [processedcount, setProcessedCount] = useState(0);
  const [cancelledcount, setCancelledCount] = useState(0);
  const [rfq_sharedcount, setRFQSharedCount] = useState(0);
  const [rejectedcount, setRejectedCount] = useState(0);
  const [completedcount, setCompletedCount] = useState(0);
  const [irrelevantcount, setIrrelevantCount] = useState(0);
  const [remindercount, setReminderCount] = useState(0);
  const [tabIndex, setTabIndex] = useState(props && props.location && props.location.tab ? props.location.tab : 0);
  // const [inactivecount, setInactivecount] = useState(0);
  // const [outofstockcount, setOutofstockcount] = useState(0);
  // const [draftcount, setDraftcount] = useState(0);
  // const [lockedcount, setLockedcount] = useState(0);


  return (
    <div>
      <div className="col-md-12 text-right">
        {IsAllowed(["10102"]) && (
          <Link className="btn btn-primary " to="/add-leads">
            ADD LEAD
          </Link>
        )}
      </div>
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList>
          <Tab>All <span class="badge badge-primary badge-counter">{allcount}</span></Tab>
          <Tab>Pending <span class="badge badge-danger badge-counter">{pendingcount}</span></Tab>

          <Tab>Processed <span class="badge badge-secondary badge-counter">{processedcount}</span></Tab>
          <Tab>Irrelevant <span class="badge badge-info badge-counter">{irrelevantcount}</span></Tab>
          <Tab>Proposal shared <span class="badge badge-info badge-counter">{rfq_sharedcount}</span></Tab>
          <Tab>Reminded <span class="badge badge-light badge-counter">{remindercount}</span></Tab>
          <Tab>Completed <span class="badge badge-success badge-counter">{completedcount}</span></Tab>
          <Tab>Cancelled <span class="badge badge-warning badge-counter">{cancelledcount}</span></Tab>
          <Tab>Rejected <span class="badge badge-dark badge-counter">{rejectedcount}</span></Tab>

        </TabList>


        <TabPanel>
          <AllLeads setAllCount={setAllCount}></AllLeads>
        </TabPanel>
        <TabPanel>
          <PendingLeads setPendingCount={setPendingCount}></PendingLeads>
        </TabPanel>
        <TabPanel>
          <ProcessedLeads setProcessedCount={setProcessedCount}></ProcessedLeads>
        </TabPanel>
        <TabPanel>
          <IrrelevantLeads setIrrelevantCount={setIrrelevantCount}></IrrelevantLeads>
        </TabPanel>
        <TabPanel>
          <RFQSharedLeads setRFQSharedCount={setRFQSharedCount}></RFQSharedLeads>
        </TabPanel>
        <TabPanel>
          <ReminderLeads setReminderCount={setReminderCount}></ReminderLeads>
        </TabPanel>
        <TabPanel>
          <CompletedLeads setCompletedCount={setCompletedCount}></CompletedLeads>
        </TabPanel>
        <TabPanel>
          <CancelledLeads setCancelledCount={setCancelledCount}></CancelledLeads>
        </TabPanel>
        <TabPanel>
          <RejectedLeads setRejectedCount={setRejectedCount}></RejectedLeads>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default Leads;
