import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import { Link } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import EditSource from "./EditSource";
import Modal from "react-modal";
import "./Catalog.css";
import IsAllowed from "../../Utils/IsAllowed";
function Sources() {

    const [loading, setLoading] = useState(false);
    const [sources, setSources] = useState([]);
    const [sourceID, setSourceID] = useState('');
    const [expandable, setExpandable] = useState('none');
    const [isOpen, setIsOpen] = useState(false);
    const openModal = (id) => {
        setIsOpen(true);
        setSourceID(id);
    };

    function closeModal() {
        setIsOpen(false);
    }

    const [name, setName] = useState('');
    const [buttonVisibility, setButtonVisibility] = useState('');

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };


    const openAddForm = () => {
        setExpandable('');
        setButtonVisibility('none');
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", name);
        axios("Xtras/sources", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    getSources();
                    alert.success(response.data.success);
                } else {
                    setLoading(false);
                    alert.error(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.error(err.response.data.error);
            });
    };



    useEffect(() => {
        getSources();
    }, []);

    const getSources = () => {
        axios("Xtras/sources", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setSources(response.data.sources);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };


    return (
        <div>
            <Modal
                isOpen={isOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <EditSource setIsOpen={setIsOpen} getSources={getSources} source_id={sourceID}></EditSource>
            </Modal>
            <div class="container-fluid">
                <h1 class="h3 mb-2 text-gray-800">Sources</h1>

                <div class="card shadow mb-4">
                    <div class="card-body">
                        <div className="row add-department justify-content-center " style={{ display: expandable }}>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="form-control form-control-lg"
                                ></input>
                            </div>

                            <div className="col-md-1">
                                <button
                                    className="btn btn-primary"
                                    onClick={handleSubmit}
                                >
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                        <div className="col-md-12 text-right">
                            {IsAllowed(["2"]) && (
                                <button
                                    className="btn btn-primary "
                                    style={{ display: buttonVisibility }}
                                    onClick={() =>
                                        openAddForm()
                                    }
                                >
                                    ADD SOURCE
                                </button>
                            )}
                        </div>

                        <div class="container-fluid">

                            <div style={{ marginTop: 50 }}>
                                <div className="table-responsive">
                                    <LoadingOverlay
                                        active={loading}
                                        styles={{
                                            overlay: (base) => ({
                                                ...base,
                                                background: "rgba(255, 255, 255, 0.9)",
                                            }),
                                            spinner: (base) => ({
                                                ...base,
                                                width: "75px",
                                                "& svg circle": {
                                                    stroke: "rgba(0, 0, 0, 0.8)",
                                                },
                                            }),
                                        }}
                                        spinner={<RingLoader loading={loading} color="#36d7b7" />}
                                        text="..."
                                    >
                                        <table className="table table-bordered  ">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Name</th>
                                                    <th>Created Date</th>
                                                    <th>Action</th>

                                                </tr>
                                            </thead>

                                            <tbody>
                                                {sources.length > 0 ? sources.map((value, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{value.name}</td>
                                                            <td>{value.created_at}</td>
                                                            <td className="space-evenly">
                                                                {IsAllowed(["2"]) && (
                                                                    <button className="btn btn-sm btn-primary" title="Edit" onClick={() => openModal(value.value)}><i className="fa fa-pen"></i></button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                }) : (<td colSpan={4} className="text-center"> No Sources</td>)}
                                            </tbody>
                                        </table>
                                    </LoadingOverlay>

                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Sources;
