import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import RingLoader from "react-spinners/RingLoader";
import axios from "../../Utils/Axios";
import { useAlert } from "react-alert";
function Profile() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  useEffect(() => {
    getUser();
  }, []);
  const getUser = () => {
    setLoading(true);
    axios(`Login/user_info`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: JSON.parse(localStorage.getItem("data")),
      },
    }).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        setUser(res.data.user);
      } else {
        setLoading(false);
        alert.show(res.error);
      }
    });
  };
  // const updateProfile = (event) => {
  //   event.preventDefault();
  //   setLoading(true);
  //   let formdata = new FormData();
  //   formdata.append("name", user.name);
  //   formdata.append("email", user.email);
  //   formdata.append("user_id", user.id);
  //   axios(`Users/update_user_profile`, {
  //     method: "POST",
  //     data: formdata,
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //       "x-api-key": JSON.parse(localStorage.getItem("data")),
  //     },
  //   })
  //     .then((res) => {
  //       if (res.data.status) {
  //         setLoading(false);
  //       } else {
  //         setLoading(false);
  //         alert.show(res.data.message);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  return (
    <LoadingOverlay
      active={loading}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.9)",
        }),
        spinner: (base) => ({
          ...base,
          width: "75px",
          "& svg circle": {
            stroke: "rgba(0, 0, 0, 0.8)",
          },
        }),
      }}
      spinner={<RingLoader loading={loading} color="#36d7b7" />}
      text="..."
    >
      <div>
        <div class="container-fluid">
          <div class="card p-5">
            <form>
              <div class="form-group">
                <label htmlFor="Email">Email</label>
                <input
                  type="email"
                  disabled={true}
                  className="form-control"
                  value={user.email}
                  id="Email"
                  placeholder="Email"
                />
              </div>
              <div class="form-group">
                <label htmlFor="Name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={user.firstname}
                  disabled={true}
                  id="Name"
                  placeholder="Name"
                />
              </div>
              <div class="form-group">
                <label htmlFor="Name">Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  value={user.mobile}
                  disabled={true}
                  id="mobile"
                  placeholder="mobile"
                />
              </div>
              {/* <div class="form-group">
              <label htmlFor="Name">Password</label>
              <input
                type="password"
                disabled={true}
                className="form-control"
                value={user.secure_password}
                onChange={(e) =>
                  setUser({
                    ...user,
                    secure_password: e.target.value,
                  })
                }
                id="Password"
                placeholder="Password"
              />
            </div> */}

              {/* <button
              type="button"
              onClick={updateProfile}
              class="btn btn-primary"
            >
              Submit
            </button> */}
            </form>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default Profile;
