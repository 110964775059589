import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
import "./Catalog.css";
function EditSource(props) {


    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [source, setSource] = useState({});
    const [sourceID, setSourceID] = useState('');
    useEffect(() => {
        getSourceDetails();
    }, [props.source_id]);

    const getSourceDetails = () => {
        axios(`Xtras/source_details?source_id=${props.source_id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setSource(response.data.source);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("name", source.name);
        formdata.append("id", props.source_id);
        axios("Xtras/update_sources", {
            method: "POST",
            data: formdata,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setLoading(false);
                    alert.success(response.data.success);
                    props.getSources();
                    props.setIsOpen(false);
                } else {
                    setLoading(false);
                    alert.error(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.error(err.response.data.error);
            });
    };

    return (
        <div class="container">
            <form method="POST">
                <div class="form-group">
                    <label for="name">Name:</label>
                    <input type="text" id="name" onChange={(e) => setSource({
                        ...source,
                        name: e.target.value,
                    })} value={source.name} name="name" className="form-control" />
                </div>
                <div class="form-group">
                    <button type="button" onClick={handleSubmit} className="btn btn-danger btn-sm "  >UPDATE</button>
                </div>
            </form>
        </div>
    );
}

export default EditSource;