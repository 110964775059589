import React, { useContext, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import IsAllowed from "./Utils/IsAllowed";
import axios from "./Utils/Axios";
import { AuthContext } from "./Context/AuthContext";
import Dashboard from "./Components/Dashboard/Dashboard";
import Header from "./Components/Templates/Header";
import SideBar from "./Components/Templates/SideBar";
import Footer from "./Components/Templates/Footer";
import Login from "./Components/Login/Login";
import Leads from "./Components/Leads/Leads"
import AddLeads from "./Components/Leads/AddLeads"
import EditLeads from "./Components/Leads/EditLeads"
import Profile from "./Components/Login/Profile";
import Departments from "./Components/Catalog/Departments";
import Sources from "./Components/Catalog/Sources";
import Users from "./Components/UserManagement/Users";
import Roles from "./Components/UserManagement/Roles"
import AddRole from "./Components/UserManagement/AddRole"
import AddUser from "./Components/UserManagement/AddUser"
import EditUser from "./Components/UserManagement/EditUser"
import AddBulkLeads from "./Components/Leads/AddBulkLeads";

function App() {
  const { role, setRole, setPermissions } = useContext(AuthContext);

  useEffect(() => {
    if (!role) {
      axios(`Login/get_user_role_permission`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setRole(res.data.users.user_role_id);
            setPermissions(res.data.permissions);
          } else {
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else if (err.request) {
            console.log(err.request);
          } else {
            console.log(err);
          }
        });
    }
  });
  return (
    <div id="wrapper">
      <Switch>
        <LoginSideBar />
      </Switch>
      <Switch>
        <LoginHeader />
      </Switch>

      <div id="content-wrapper">
        <div id="content">
          <Switch>
            <Route path="/login" exact component={Login} />
            <ProtectedRoute path="/" exact component={Dashboard} />
            <ProtectedRoute path="/profile" exact component={Profile} />
            <ProtectedRoute path="/leads" exact component={Leads} />
            <ProtectedRoute path="/add-leads" exact component={AddLeads} />
            <ProtectedRoute path="/add-bulk-leads" exact component={AddBulkLeads} />
            <ProtectedRoute path="/sources" exact component={Sources} />
            <ProtectedRoute path="/departments" exact component={Departments} />
            <ProtectedRoute path="/users" exact component={Users} />
            <ProtectedRoute path="/add-user" exact component={AddUser} />
            <ProtectedRoute path="/roles" exact component={Roles} />
            <ProtectedRoute path="/add-role" exact component={AddRole} />
            
            <ProtectedRoute
              path="/edit-lead/:id"
              exact
              component={EditLeads}
            />
            <ProtectedRoute
              path="/edit-user/:id"
              exact
              component={EditUser}
            />
          </Switch>
        </div>
      </div>
      <Switch>
        <LoginFooter />
      </Switch>
    </div>
  );
}

const ProtectedRoute = ({ component: Component, path }) => {
  return (
    <Route
      path={path}
      render={(props) => {
        return localStorage.getItem("data") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              refresh: true,
              state: {
                prevLocation: path,
                error: "You need to login first!",
              },
            }}
          />
        );
      }}
    />
  );
};
const LoginHeader = () => {
  return localStorage.getItem("data") ? <Header rand={Math.random()} /> : "";
};
const LoginSideBar = () => {
  return localStorage.getItem("data") ? <SideBar /> : "";
};
const LoginFooter = () => {
  return localStorage.getItem("data") ? <Footer /> : "";
};

export default App;
