import React, { useEffect, useState } from "react";
import axios from "../../Utils/Axios";
import RingLoader from "react-spinners/RingLoader";
import LoadingOverlay from "react-loading-overlay";
import { useHistory, Link } from "react-router-dom";
import { useAlert } from "react-alert";
import "./Users.css";
import IsAllowed from "../../Utils/IsAllowed";
function Users() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const alert = useAlert();

    useEffect(() => {
        getUsers();
    }, []);

    const viewDetails = (id) => {
        history.push(`/edit-user/${id}`);
    };

    const getUsers = () => {
        axios("Users/users", {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: JSON.parse(localStorage.getItem("data")),
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setUsers(response.data.users);
                } else {
                    setLoading(false);
                    alert.show(response.data.error);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert.show(err.response.data.error);
            });
    };

    return (
        <div>
            <div class="container-fluid">
                <h1 class="h3 mb-2 text-gray-800">Users</h1>


                <div class="card shadow mb-4">
                    <div className="col-md-12 text-right">
                        {IsAllowed(["30102"]) && (
                            <Link className="btn btn-primary " to="/add-user">
                                ADD USER
                            </Link>
                        )}
                    </div>
                    <div class="card-body">
                        <div class="container-fluid">
                            <div style={{ marginTop: 50 }}>
                                <div className="table-responsive">
                                    <LoadingOverlay
                                        active={loading}
                                        styles={{
                                            overlay: (base) => ({
                                                ...base,
                                                background: "rgba(255, 255, 255, 0.9)",
                                            }),
                                            spinner: (base) => ({
                                                ...base,
                                                width: "75px",
                                                "& svg circle": {
                                                    stroke: "rgba(0, 0, 0, 0.8)",
                                                },
                                            }),
                                        }}
                                        spinner={<RingLoader loading={loading} color="#36d7b7" />}
                                        text="..."
                                    >
                                        <table className="table table-bordered  ">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Created Date</th>
                                                    <th>Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.length > 0 ? users.map((value, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{value.firstname + ' ' + value.lastname}</td>
                                                            <td>{value.email}</td>
                                                            <td>{value.created_at}</td>
                                                            <td className="space-evenly">
                                                                {IsAllowed(["30103"]) && (
                                                                    <button className="btn btn-sm btn-primary" title="Edit" onClick={() => viewDetails(value.id)}><i className="fa fa-pen"></i></button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                }) : (<td colSpan={5} className="text-center"> No Users</td>)}
                                            </tbody>
                                        </table>
                                    </LoadingOverlay>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Users;
